import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";


function OutcomeGraph ({inputDataArray, labelsArray}){

    const options = {
      plugins: {
            title: {
                display: true,
                text: 'Enquiry Outcomes',
              },
          },
          responsive: true,
          scaleShowValues: true,
          scales: {
              x: {
                stacked: true,
                display: true,
                title: {
                    display: true,
                    text: 'Enquiry Creation Date'
                }
              },
              y: {
                stacked: true,
              }
          },
    };

    const n = inputDataArray.length;
    const labels = [];
    const unfin = [];
    const pos = [];
    const neg = [];

    for (let i = 0; i < n; i++) {
        var arr = JSON.parse(JSON.stringify(inputDataArray[i]));
        unfin.push(arr[0]);
        pos.push(arr[1]);
        neg.push(arr[2]);

        const l_i = (labelsArray != null && labelsArray.length > 0)? labelsArray[n - (i + 1)] : i;
        labels.push(l_i);
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: `negative`,
                backgroundColor: 'rgb(85, 96, 116)',
                data: neg,
            },
            {
                label: `positive`,
                backgroundColor: 'rgb(49, 74, 148)',
                data: pos,
            },
            {
                label: `unfinished`,
                backgroundColor: 'rgb(255, 145, 10)',
                data: unfin,
            },
        ],
    };

    // TODO: get autosizer working and make width + height dynamic
    const w = 1200;
    const h = 400;
    return (
        <Bar className="main-content" data={data} options={options} width={w} height={h}/>
    );
};

export default OutcomeGraph;
