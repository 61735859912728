import { AppContext } from './AppContext';
import React, { useEffect } from 'react';
import GuestView from './components/GuestView';
import HomeContent from './components/HomeContent';
import NavigationBar from './components/NavigationBar';
import OutcomeContent from './components/OutcomeContent';
import { Route, Routes} from 'react-router-dom';
import EnquiryDetailsContent from './components/EnquiryDetailsContent';
import SessionDetailsContent from './components/SessionDetailsContent';
import QuestionTimeContent from './components/QuestionTimeContent';
import LoadingView from './components/LoadingView';
//import MaintView from './components/MaintView';


export default function App() {

    const context = React.useContext(AppContext);

    useEffect(() => {

        const loadAuthentication = async () => {
			if (window.localStorage.getItem("aora_dashboard_jwt")) {
                return await context.init()
			}
            
            // Attempt OAuth
            var code = context.getURLPeram(window.location.href, "code")

			if (code) {
				return await context.loginOAuth(code)
			}
            
            context.reset()
		}

		loadAuthentication()

    }, [])

    if (!context.state.appLoaded) return <LoadingView />

    if (!context.state.username) return <GuestView />

    return (
        <>
            <NavigationBar />
            <main className='horizontal-padding'>
                <Routes>
                    <Route path="/" exact Component={HomeContent}/>
                    <Route path="/outcomes" exact Component={OutcomeContent}/>
                    <Route path="/enquiry-details" exact Component={EnquiryDetailsContent}/>
                    <Route path="/question-times" exact Component={QuestionTimeContent}/>
                    <Route path="/session-details" exact Component={SessionDetailsContent}/>
                    {/* <Route path='/maint' exact Component={MaintView}/> */}
                </Routes>
            </main>
        </>
    )
}