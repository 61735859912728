import {AppContext} from '../AppContext';
import React from 'react';
import logo from '../images/logo-block-white.png';

export default function LoadingView () {
    return (
        <div className="guest-view">
            <div style={{width: "180px", textAlign: "center", margin: "auto", marginTop: "40vh"}}>
                <img id="header_image"src={logo} alt="aora-logo"/>
                <p style={{color: "white", margin: 0}}>Loading dashboard</p>
            </div>
        </div>
    )
}