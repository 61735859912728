import React, {useContext, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import AppProvider, {AppContext} from '../AppContext';
import logo from '../images/logo-block-white.png';
import {history} from '../index';


function NavLinks({isLoggedIn, isAdmin}){

    const {pathname} = useLocation();

    //const [currentPage, _] = useState(window.location.pathname)
    // links' class assigned so that the selected one can be highlighted
    const homeLinkClass = pathname === "/" ? "linkbutton-selected" : "linkbutton";
    const outcomeLinkClass = pathname === "/outcomes" ? "linkbutton-selected" : "linkbutton";
    const enquiryTimeLinkClass = pathname === "/enquiry-details" ? "linkbutton-selected" : "linkbutton";
    const questionTimeLinkClass = pathname === "/question-times" ? "linkbutton-selected" : "linkbutton";
    const sessionDetailsLinkClass = pathname === "/session-details" ? "linkbutton-selected" : "linkbutton";    

    if (!isLoggedIn){
        return <Link to="/" exact class={homeLinkClass}>Home</Link>
    }
    else if (!isAdmin){
        return (
            <div>
                <Link to="/" exact class={homeLinkClass}>Home</Link>
                <Link to="/outcomes" exact class={outcomeLinkClass}>Outcomes</Link>
                <Link to="/enquiry-details" exact class={enquiryTimeLinkClass}>Enquiry Details</Link>
                <Link to="/session-details" exact class={sessionDetailsLinkClass}>Session Details</Link>
                <Link to="/question-times" exact class={questionTimeLinkClass}>Question Times</Link>
            </div>
        );
    }
    else{
        return (
            <div>
                <Link to="/" exact class={homeLinkClass}>Home</Link>
                <Link to="/outcomes" exact class={outcomeLinkClass}>Outcomes</Link>
                <Link to="/enquiry-details" exact class={enquiryTimeLinkClass}>Enquiry Details</Link>
                <Link to="/session-details" exact class={sessionDetailsLinkClass}>Session Details</Link>
                <Link to="/question-times" exact class={questionTimeLinkClass}>Question Times</Link>
                {/* <Link to="/servers" exact class={monitoringLinkClass}>Servers</Link> */}
            </div>
        );
    }
}


export default function NavigationBar () {

    const context = useContext(AppContext);
    const isAdmin = context.currentUserIsAdmin()    

    return (
        <header>
            <nav>
                <div id="header_logo" style={{display:'flex', justifyContent: "space-between"}} className="horizontal-padding">
                    <div>
                        <a href="https://www.aoralaw.com">
                            <img id="header_image"src={logo} alt="aora-logo"/>
                        </a>
                        <h1 id="header_title">Dashboard</h1>
                    </div>
                    
                    <div className="auth-widget">
                        <span>{context.state.username}</span>
                        <button onClick={() => context.reset()}>logout</button>
                    </div>
                </div>

                <NavLinks key={history.location.pathname} isLoggedIn={!!context.state.username} isAdmin={isAdmin}/>
            </nav>
        </header>
    )
}