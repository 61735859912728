import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../AppContext';
import ClipboardElement from './ClipboardElement';
import QuestionTimeTable from './QuestionTimeTable';
import Ajax from '../Ajax';

const defaultStatus = "UKLLR_SW_P";


function TimingOptionsBar(){

    const today = new Date();
    const oneWeekAgo =  new Date(today - 7 * 24 * 60 * 60 * 1000);
    const defaultStartdate = `${oneWeekAgo.toISOString().slice(0,10)}`;
    const defaultEnddate = `${today.toISOString().slice(0,10)}`;

    console.log(`TimingOptionsBar(): initialised with dates ${defaultStartdate} - ${defaultEnddate}`);

    const context = useContext(AppContext);

    const [startdate, setStartdate] = useState(defaultStartdate)
    const onStartDateChange = e => { setStartdate(e.target.value) }

    const [enddate, setEnddate] = useState(defaultEnddate)
    const onEndDateChange = e => { setEnddate(e.target.value) }

    const [status, setStatus] = useState(null) // TODO: update this!
    const onStatusChange = e => { setStatus(e.target.value) }

    const [environment, setEnvironment] = useState("stg")
    const [validCustomers, setValidCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(-1)

    useEffect(() => {
        Ajax.Usage.GetCustomers(environment).then((vcs) => {
            console.log("valid-customers", vcs)
            setValidCustomers(vcs)
        })
    }, [])


    const fetchTimingData = async () => {

        console.log(`fetchTimingData(startdate = ${startdate}, enddate = ${enddate})`);
        var data = await Ajax.Timing.GetTimingInfo(startdate, enddate, status, environment, customerId);

        if (data != null){

            const res = JSON.parse(JSON.stringify(data)); // seems like a dict
            var dict = {res}["res"];
            var sample = [];

            for (var key in dict) {
                if (dict.hasOwnProperty(key)) {
                    var keyarr = [ key ]
                    keyarr.push(...dict[key]);
                    sample.push(keyarr);
                }
            }

            context.setTimingData(sample);
        } else {
            context.setTimingData(null);
        }
    }

    var updateClicked = () => {
        
        var updBtn = document.getElementById("updateButton");
        updBtn.disabled = true;
        
        fetchTimingData();

        setTimeout(function() { updBtn.disabled = false; }, 1000);
    }

    const res = context.state.customers;
    console.log(res);

    return (
        <>
            <div className='table-toolbar'>
                <div className="field">
                    <label>Start:</label>
                    <input id="startdate" type="date" name="startdate" onChange={onStartDateChange} value={startdate} />
                </div>
                <div className="field">
                    <label>End:</label>
                    <input id="enddate" type="date" name="enddate" onChange={onEndDateChange} value={enddate}/>
                </div>
                <div className="field">
                    <label>Environment:</label>
                    <select value={environment} onChange={(e) => setEnvironment(e.target.value)}>
                        <option value="dev">Development</option>
                        <option value="stg">Staging</option>
                        <option value="prod">Production</option>
                    </select>
                </div>
                {/* <div className="field">
                    <label>Status:</label>
                    <input type="text" id="status" name="status" maxlength="10" defaultValue={defaultStatus} onChange={onStatusChange} />
                </div> */}
                <div className="field">
                    <label>Customer</label>
                    <select value={customerId} onChange={(e) => setCustomerId(e.target.value)}>
                        <option value={-1}>All customers</option>
                        {validCustomers.map((customer, i) => (
                            <option value={customer[0]}>{customer[1]}</option>
                        ))}
                    </select>
                </div>
                <div className="field" style={{marginLeft: "auto"}}>
                    <button id="updateButton" class="linkbutton" onClick={() => updateClicked()}>Update</button>
                </div>
            </div>
        </>
    );
}


function QuestionTimeContent(){

    const context = React.useContext(AppContext);
    const timingData = context.state.timingData;

    var optBar = TimingOptionsBar();
    var mainContent = <p>No timing data loaded. Adjust parameters and click update to retrieve some.</p>

    if (timingData != null){
        mainContent = <>            
            <h3 style={{marginTop: 15, marginBottom : 5}}>Question times:</h3>
            <QuestionTimeTable />
            <ClipboardElement labelStr = "Raw data" dataStr = {JSON.stringify(timingData)} />
        </>
    }

    return (
        <>
            {optBar}
            <main>{mainContent}</main>
        </>
    );
}

export default QuestionTimeContent;