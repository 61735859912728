import React, {useContext, useEffect, useState} from 'react';
import Ajax from '../Ajax'
import {AppContext} from '../AppContext';
import ClipboardElement from './ClipboardElement';
import OutcomeGraph from './OutcomeGraph';
import ErrorBoundary from '../misc/ErrorBoundary';


const defaultPeriod = "daily";
const defaultNumber = 7;
const defaultStatus = "UKLLR_SW_P";


function getShortDateStr(date, inclYear = true, inclMonth = true, inclDay = true){

    var mstr = "???";

    switch (date.getMonth()) {
        case 0: mstr = "Jan"; break;
        case 1: mstr = "Feb"; break;
        case 2: mstr = "Mar"; break;
        case 3: mstr = "Apr"; break;
        case 4: mstr = "May"; break;
        case 5: mstr = "Jun"; break;
        case 6: mstr = "Jul"; break;
        case 7: mstr = "Aug"; break;
        case 8: mstr = "Sep"; break;
        case 9: mstr = "Oct"; break;
        case 10: mstr = "Nov"; break;
        case 11: mstr = "Dec"; break;
        default: mstr = "???";
    }

    var dateStr = (inclDay) ? `${date.getDate()}` : '';
    dateStr += (inclMonth) ? ` ${mstr}` : '';
    dateStr += (inclYear) ? ` ${date.getFullYear()}` : '';

    return dateStr;
}


function getPeriodDates(n, period) {

    const msPerDay = 24 * 60 * 60 * 1000;
    const today = new Date();
    const periodStrings = [];

    if (period =='daily'){
        for (let i = 0; i < n; ++i) {
            const date = new Date(today.getTime() - i * msPerDay);
            periodStrings.push(getShortDateStr(date, false));
        }
    } else if (period =='weekly'){
        for (let j = 0; j < n; ++j) {
            const d1 = new Date(today.getTime() - (7*j + 6) * msPerDay);
            const d2 = new Date(today.getTime() - 7*j * msPerDay);
            const inclYear = d1.getFullYear() != d2.getFullYear();
            const weekStr = getShortDateStr(d1, inclYear) + ' - ' + getShortDateStr(d2, inclYear);
            periodStrings.push(weekStr);
        }
    } else if (period =='monthly'){
        for (let k = 0; k < n; ++k) {
            const d1 = new Date(today.getFullYear(), today.getMonth() - k, 1);
            periodStrings.push(getShortDateStr(d1, true, true, false));
        }
    } else { // ??
        periodStrings = [];
    }


    return periodStrings;
}


function OutcomeOptionsBar(){

    const context = useContext(AppContext);

    const [period, setPeriod] = useState(defaultPeriod);
    const [number, setNumber] = useState(defaultNumber);
    const [status, setStatus] = useState(defaultStatus);
    const [validCustomers, setValidCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(-1);
    const [environment, setEnvironment] = useState("stg");

    const updateValidCustomers = (env) => Ajax.Usage.GetCustomers(env).then((vcs) => setValidCustomers(vcs))

    const onStatusChange = e => { setStatus(e.target.value) }
    const onPeriodChange = e => { setPeriod(e.target.value) }
    const onNumberChange = e => { setNumber(e.target.value) }
    const onEnvironmentChange = e => {
        setEnvironment(e.target.value);
        updateValidCustomers(e.target.value);
    };

    useEffect(() => {
        updateValidCustomers(environment)
    }, []);

    const fetchOutcomeData = async () => {

        var updBtn = document.getElementById("updateButton");
        updBtn.disabled = true;
                
        var data = await Ajax.Usage.GetOutcomes(period, number, status, environment, customerId).catch((response) => {
            console.log("Error getting outcomes", response)
        })
        const labels = data != null ? getPeriodDates(data.length, period) : [];
        context.setOutcomeData(data);
        context.setOutcomeLabels(labels);

        setTimeout(function() { updBtn.disabled = false; }, 1000);
    }

    return (
        <>
            <div className='table-toolbar'>
                <div className="field">
                    <label>Period:</label>
                    <select value={period} onChange={onPeriodChange}>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <div className="field">
                    <label>Periods:</label>
                    <input id="number" type="number" name="number" max="100" defaultValue={defaultNumber} onChange={onNumberChange} />
                </div>
                <div className="field">
                    <label>Environment:</label>
                    <select value={environment} onChange={onEnvironmentChange}>
                        <option value="dev">Development</option>
                        <option value="stg">Staging</option>
                        <option value="prod">Production</option>
                    </select>
                </div>
                <div className="field">
                    <label>Customer</label>
                    <select value={customerId} onChange={(e) => setCustomerId(e.target.value)}>
                        <option value={-1}>All customers</option>
                        {validCustomers.map((customer, i) => (
                            <option value={customer[0]}>{customer[1]}</option>
                        ))}
                    </select>
                </div>
                <div className="field">
                    <label>Status:</label>
                    <input type="text" id="status" name="status" maxlength="10" defaultValue={defaultStatus} onChange={onStatusChange} />
                </div>
                <div className="field" style={{marginLeft: "auto"}}>
                    <button id="updateButton" class="linkbutton" onClick={() => fetchOutcomeData()}>Update</button>
                </div>
            </div>
        </>
    )
}


export default function OutcomeContent () {

    const context = React.useContext(AppContext);
    const outcomeData = context.state.outcomeData;
    const outcomeLabels = context.state.outcomeLabels;

    return (
        <ErrorBoundary>
            <OutcomeOptionsBar />

            {outcomeData ? (
                <>
                    <h3 style={{marginTop: 15}}>Outcome graph: </h3>
                    <OutcomeGraph inputDataArray = {outcomeData} labelsArray = {outcomeLabels}/>
                    <ClipboardElement labelStr = "Raw data" dataStr = {JSON.stringify(outcomeData)} />
                </>
            ) : (
                <p>No outcome data loaded. Adjust parameters and click update to retrieve some.</p>
            )}
        </ErrorBoundary>
    )
}
