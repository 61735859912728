import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../AppContext';
import ClipboardElement from './ClipboardElement';
import Ajax from '../Ajax';
import EnquiryDetailsTable from './EnquiryDetailsTable';

const defaultStatus = "UKLLR_SW_P";


    function EnquiryDetailsOptionsBar(){

        const today = new Date();
        const oneWeekAgo =  new Date(today - 7 * 24 * 60 * 60 * 1000);
        const defaultStartdate = `${oneWeekAgo.toISOString().slice(0,10)}`;
        const defaultEnddate = `${today.toISOString().slice(0,10)}`;
    
        console.log(`TimingOptionsBar(): initialised with dates ${defaultStartdate} - ${defaultEnddate}`);
    
        const context = useContext(AppContext);
    
        const [startdate, setStartdate] = useState(defaultStartdate)
        const onStartDateChange = e => { setStartdate(e.target.value) }
    
        const [enddate, setEnddate] = useState(defaultEnddate)
        const onEndDateChange = e => { setEnddate(e.target.value) }
    
        const [status, setStatus] = useState(null) // TODO: update this!
        const onStatusChange = e => { setStatus(e.target.value) }
    
        const [environment, setEnvironment] = useState("stg")

    
    
        const fetchEnquiryDetailsData = async () => {
    
            var data = await Ajax.Timing.GetEnquiryTimes(startdate, enddate, environment)
    
            if (data != null){
                const res = JSON.parse(JSON.stringify(data)); // seems like a dict
                var dict = {res}["res"];
                var sample = [];
    
                for (var key in dict) {
                    if (dict.hasOwnProperty(key)) {
                        var keyarr = [ key ]
                        keyarr.push(...dict[key]);
                        sample.push(keyarr);
                    }
                }
    
                console.log(`sample: ${sample}`);
                context.setEnquiryDetailsData(sample);
            } else {
                context.setEnquiryDetailsData(null);
            }
        }
    
        var updateClicked = () => {

            var updBtn = document.getElementById("updateButton");
            updBtn.disabled = true;

            fetchEnquiryDetailsData();

            setTimeout(function() { updBtn.disabled = false; }, 1000);
        }

        return (
            <>
                <div className='table-toolbar'>
                    <div className="field">
                        <label>Start:</label>
                        <input id="startdate" type="date" name="startdate" onChange={onStartDateChange} value={startdate} />
                    </div>
                    <div className="field">
                        <label>End:</label>
                        <input id="enddate" type="date" name="enddate" onChange={onEndDateChange} value={enddate}/>
                    </div>
                    <div className="field">
                        <label>Environment:</label>
                        <select value={environment} onChange={(e) => setEnvironment(e.target.value)}>
                            <option value="dev">Development</option>
                            <option value="stg">Staging</option>
                            <option value="prod">Production</option>
                        </select>
                    </div>
                    
                    <div className="field" style={{marginLeft: "auto"}}>
                        <button id="updateButton" class="linkbutton" onClick={() => updateClicked()}>Update</button>
                    </div>
                </div>
    
            </>
        );
    
    }

    function EnquiryDetailsContent(){

        const context = React.useContext(AppContext);
        const enquiryDetailsData = context.state.enquiryDetailsData;
    
        var optBar = EnquiryDetailsOptionsBar();
        var mainContent = <p>No enquiry details loaded. Adjust parameters and click update to retrieve some.</p>
    
        if (enquiryDetailsData != null){
            mainContent = <>            
                <h3 style={{marginTop: 15, marginBottom : 5}}>Enquiry Details:</h3>
                <EnquiryDetailsTable/>
                <ClipboardElement labelStr = "Raw data" dataStr = {JSON.stringify(enquiryDetailsData)} />
            </>
        }
    
        return (
            <>
                {optBar}
                <main>{mainContent}</main>
            </>
        );
    }
    

export default EnquiryDetailsContent;