import React from "react"
import { AppContext } from "../AppContext"


export default function Errors () {
    
    const context = React.useContext(AppContext);

    return (
        <div className="errors">
            {context.state.errors.map((error, i) => (
                <div className="error" key={i} onClick={() => context.closeError(i)}>
                    {error}
                </div>
            ))}
        </div>
    )
}