import './App.css';
import App from './App';
import ReactDom from 'react-dom';
import AppProvider from './AppContext';
import Errors from './components/Errors';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';


export const history = createBrowserHistory()


ReactDom.render((
    <AppProvider>
        <BrowserRouter>
            <Errors />
            <App />
        </BrowserRouter>
    </AppProvider>
), document.getElementById("root"));