import React, {useContext, useState} from 'react';
import {AutoSizer, Column, Table} from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import {AppContext} from '../AppContext';


export default function SessionDetailsTable(){

    const context = React.useContext(AppContext);
    const data = context.state.sessionDetailsData;

    const [sortKey, setSortKey] = useState("")
    function updateSortKey(key){

        var sortIndex = 0;

        if (key === "session_id") sortIndex = 0;
        if (key === "customer_name") sortIndex = 1;
        if (key === "user_id") sortIndex = 2;
        if (key === "username") sortIndex = 3;
        if (key === "ip_address") sortIndex = 4;
        if (key === "start_dt") sortIndex = 5;
        if (key === "end_dt") sortIndex = 6;

        data.sort(function(row1, row2){return row2[sortIndex] - row1[sortIndex]});
        setSortKey(key);
    }


    function ColHeader ({colkey}){

        var disp_text = colkey;
        var hover_text = "";
        if (colkey === "session_id"){
            disp_text = "Session Id";
            hover_text = "Identifier for the session";
        }
        if (colkey === "customer_name"){
            disp_text = "Customer Name";
            hover_text = "";
        }
        if (colkey === "user_id"){
            disp_text = "User ID";
            hover_text = "";
        }
        if (colkey === "username"){
            disp_text = "Login";
            hover_text = "";
        }
        if (colkey === "ip_address"){
            disp_text = "IP Address";
            hover_text = "";
        }
        if (colkey === "start_dt"){
            disp_text = "Started";
            hover_text = "When the session started";
        }
        if (colkey === "end_dt"){
            disp_text = "Ended";
            hover_text = "When the session ended";
        }

        const sort_icon = (sortKey === colkey) ? "▼" : "";
        const colText = <span>{disp_text}{sort_icon}</span>;
        return(
            <button title={hover_text} className="button-tableheader" onClick={() => { updateSortKey(colkey);}}>
                <p>{colText}</p>
            </button>
        );
    };

    const createData = (session_id, customer_name, user_id, username, ip_address, start_dt, end_dt) => {
        return {
            session_id: session_id,
            user_id: user_id,
            customer_name: customer_name,
            username: username,
            ip_address : ip_address,
            start_dt: start_dt,
            end_dt: end_dt
        };
    };

    const list = data.map((row, index) => {
        return createData(row[0], row[1], row[2], row[3], row[4], row[5], row[6]);
    })

    // TODO: get autosizer working and make width + height dynamic
    const w = 1500;
    const h = 500;
    return (
        <Table
            className='main-content'
            headerClassName='table-header'
            headerStyle={{margin: 0, padding: 5}}
            width={w}
            height={h}
            headerHeight={36}
            rowHeight={24}
            rowCount={list.length}
            rowGetter={({index}) => list[index]}>
            <Column dataKey="session_id" width={w*0.05} headerRenderer={() => <ColHeader colkey="session_id"/>}/>
            <Column dataKey="customer_name" width={w*0.2} headerRenderer={() => <ColHeader colkey="customer_name"/>}/>
            <Column dataKey="user_id" width={w*0.05} headerRenderer={() => <ColHeader colkey="user_id"/>}/>
            <Column dataKey="username" width={w*0.2} headerRenderer={() => <ColHeader colkey="username"/>}/>
            <Column dataKey="ip_address" width={w*0.2} headerRenderer={() => <ColHeader colkey="ip_address"/>}/>
            <Column dataKey="start_dt" width={w*0.125} headerRenderer={() => <ColHeader colkey="start_dt"/>}/>
            <Column dataKey="end_dt" width={w*0.125} headerRenderer={() => <ColHeader colkey="end_dt"/>}/>
        </Table>

    );
}
