import React, {useContext, useState} from 'react';
import {AutoSizer, Column, Table} from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import {AppContext} from '../AppContext';


export default function QuestionTimeTable(){

    const context = React.useContext(AppContext);
    const data = context.state.timingData;

    const [sortKey, setSortKey] = useState("")
    function updateSortKey(key){

        var sortIndex = 0;

        if (key === "question") sortIndex = 0;
        if (key === "asked") sortIndex = 1;
        if (key === "attempts") sortIndex = 2;
        if (key === "mean_t") sortIndex = 3;
        if (key === "med_t") sortIndex = 4;
        if (key === "sd_t") sortIndex = 5;

        data.sort(function(row1, row2){return row2[sortIndex] - row1[sortIndex]});
        setSortKey(key);
    }


    function ColHeader ({colkey}){

        var disp_text = colkey;
        var hover_text = "";
        if (colkey === "question"){
            disp_text = "Question";
            hover_text = "Identifier for the type of question asked";
        }
        if (colkey === "asked"){
            disp_text = "Asked";
            hover_text = "The number of times this type of questions was during an enquiry";
        }
        if (colkey === "attempts"){
            disp_text = "Valid";
            hover_text = "The proportion of provided answers that passed validation";
        }
        if (colkey === "mean_t"){
            disp_text = "μ(s)";
            hover_text = "The average time taken to provide an answer";
        }
        if (colkey === "med_t"){
            disp_text = "η(s)";
            hover_text = "The median time taken to provide an answer (less susceptible to outliers than the average)";
        }
        if (colkey === "sd_t"){
            disp_text = "σ(s)";
            hover_text = "The standard deviation in time taken to provide an answer (gives an indication of variability)";
        }

        const sort_icon = (sortKey === colkey) ? "▼" : "";
        const colText = <span>{disp_text}{sort_icon}</span>;
        return(
            <button title={hover_text} className="button-tableheader" onClick={() => { updateSortKey(colkey);}}>
                <p>{colText}</p>
            </button>
        );
    };

    const createData = (question, asked, attempts, mean_t, med_t, sd_t) => {
        return {
            question: question,
            asked: asked,
            attempts: attempts,
            mean_t: mean_t,
            med_t: med_t,
            sd_t: sd_t
        };
    };

    const list = data.map((row, index) => {
        return createData(row[0], row[1], row[2], row[3], row[4], row[5], row[6]);
    })

    // TODO: get autosizer working and make width + height dynamic
    const w = 1500;
    const h = 500;
    return (
        <Table
            className='main-content'
            headerClassName='table-header'
            headerStyle={{margin: 0, padding: 10}}
            width={w}
            height={h}
            headerHeight={36}
            rowHeight={24}
            rowCount={list.length}
            rowGetter={({index}) => list[index]}>
            <Column dataKey="question" width={w*0.7} headerRenderer={() => <ColHeader colkey="question"/>}/>
            <Column dataKey="asked" width={w*0.06} headerRenderer={() => <ColHeader colkey="asked"/>}/>
            <Column dataKey="attempts" width={w*0.06} headerRenderer={() => <ColHeader colkey="attempts"/>}/>
            <Column dataKey="mean_t" width={w*0.06} headerRenderer={() => <ColHeader colkey="mean_t"/>}/>
            <Column dataKey="med_t" width={w*0.06} headerRenderer={() => <ColHeader colkey="med_t"/>}/>
            <Column dataKey="sd_t" width={w*0.06} headerRenderer={() => <ColHeader colkey="sd_t"/>}/>
        </Table>
    );
}
