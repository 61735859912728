import {AppContext} from '../AppContext';
import React from 'react';

export default function GuestView () {

    const context = React.useContext(AppContext);

    var codeChallenge = context.getCookie("AORA-OAuth-dashboard-PKCE-code-challenge")
    var oauthProvider = context.getCookie("AORA-OAuth-dashboard-provider")
    var clientId = context.getCookie("AORA-OAuth-dashboard-client-id")
    var tenantId = context.getCookie("AORA-OAuth-dashboard-tenant-id")
    var redirectUri = context.getCookie("AORA-OAuth-dashboard-redirect-uri")
    redirectUri = decodeURI(redirectUri)
    
    return (
        <div className="guest-view">
            <div className="login-form">
                <h2>Sign in</h2>

                {codeChallenge ? (
                    <a
                        style={{width: "fit-content", display: "flex", color: "#535353", textDecoration: "none", alignItems: "center", gap: "10px", background: "#d0d0d0", padding: "4px 18px", borderRadius: "4px"}}
                        href={`https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?` +
                        `client_id=${clientId}&` +
                        `state=${oauthProvider}&` +
                        `redirect_uri=${redirectUri}&` +
                        "code_challenge=" + codeChallenge + "&" +
                        "scope=https%3A%2F%2Fgraph.microsoft.com%2Fopenid&" +
                        "response_type=code&" +
                        "code_challenge_method=S256"
                    }>
                        Sign in with
                        <img height="22" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Microsoft_logo_%282012%29.svg/2560px-Microsoft_logo_%282012%29.svg.png" />
                    </a>
                ) : (
                    <p>You don't have any sign in options</p>
                )}
            </div>
        </div>
    )
}