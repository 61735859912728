import React from "react";


export default class ErrorBoundary extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            hasError: false,
            error: "",
            errorInfo: null
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error }
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({errorInfo: errorInfo})
    }


    render () {
        
        var showStackTrace = process.env.NODE_ENV === "development" && this.state.errorInfo?.componentStack

        if (!this.state.hasError) {
            return <>{this.props.children}</>
        }


        return (
            <div className="error-boundary">
                <h3>Front end error</h3>

                <p>{this.state.error.toString()}</p>

                {showStackTrace ? <p className="code">{this.state.errorInfo?.componentStack}</p> : null}
            </div>
        )
    }
  }