// Custom UI component that displays text in a textarea and allows the user to copy it to the clipboard. 
function ClipboardElement({labelStr, dataStr}) {

    var textElt = <textarea id="clipboardTextArea" rows={6} value={dataStr}/>

    var copyText = () => {
        var clipboardElt = document.getElementById("clipboardTextArea");
        navigator.clipboard.writeText(clipboardElt.value);
        clipboardElt.classList.add('highlight');
        setTimeout(() => clipboardElt.classList.remove('highlight'), 1000);
    }

    return (
        <>
            <div>
                <h3 style={{display : 'inline'}}>{labelStr}: </h3>
                <button style={{display : 'inline', fontSize: 20, textAlign : 'right'}} onClick={copyText}>copy</button>
            </div>
            {textElt}
        </>
    );
}

export default ClipboardElement;