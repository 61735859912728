import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../AppContext';
import ClipboardElement from './ClipboardElement';
import Ajax from '../Ajax';
import SessionDetailsTable from './SessionDetailsTable';
import { Component } from 'react';


    function SessionDetailsOptionsBar(){

        const today = new Date();
        const oneWeekAgo =  new Date(today - 7 * 24 * 60 * 60 * 1000);
        const defaultStartdate = `${oneWeekAgo.toISOString().slice(0,10)}`;
        const defaultEnddate = `${today.toISOString().slice(0,10)}`;

        console.log(`TimingOptionsBar(): initialised with dates ${defaultStartdate} - ${defaultEnddate}`);

        const context = useContext(AppContext);

        const [startdate, setStartdate] = useState(defaultStartdate)
        const onStartDateChange = e => { setStartdate(e.target.value) }

        const [enddate, setEnddate] = useState(defaultEnddate)
        const onEndDateChange = e => { setEnddate(e.target.value) }

        const [environment, setEnvironment] = useState("stg")


        const fetchSessionDetailsData = async () => {

            console.log(`fetchSessionDetailsData(startdate = ${startdate}, enddate = ${enddate})`);
            var data = await Ajax.Timing.GetSessionDetails(startdate, enddate, environment)

            if (data != null){

                const res = JSON.parse(JSON.stringify(data)); // seems like a dict
                var dict = {res}["res"];
                var sample = [];

                for (var key in dict) {
                    if (dict.hasOwnProperty(key)) {
                        var keyarr = [ key ]
                        keyarr.push(...dict[key]);
                        sample.push(keyarr);
                    }
                }

                context.setSessionDetailsData(sample);
            } else {
                context.setSessionDetailsData(null);
            }
        }

        var updateClicked = () => {
            
            var updBtn = document.getElementById("updateButton");
            updBtn.disabled = true

            fetchSessionDetailsData();

            setTimeout(function() { updBtn.disabled = false; }, 1000);
        }


        return (
            <>
                <div className='table-toolbar'>
                    <div className="field">
                        <label>Start:</label>
                        <input id="startdate" type="date" name="startdate" onChange={onStartDateChange} value={startdate} />
                    </div>
                    <div className="field">
                        <label>End:</label>
                        <input id="enddate" type="date" name="enddate" onChange={onEndDateChange} value={enddate}/>
                    </div>
                    <div className="field">
                        <label>Environment:</label>
                        <select value={environment} onChange={(e) => setEnvironment(e.target.value)}>
                            <option value="dev">Development</option>
                            <option value="stg">Staging</option>
                            <option value="prod">Production</option>
                        </select>
                    </div>

                    <div className="field" style={{marginLeft: "auto"}}>
                        <button id="updateButton" class="linkbutton" onClick={() => updateClicked() }>Update</button>
                    </div>
                </div>

            </>
        );

    }

    function SessionDetailsContent(){

        const context = React.useContext(AppContext);
        const sessionDetailsData = context.state.sessionDetailsData;

        var optBar = SessionDetailsOptionsBar();
        var mainContent = <p>No session details loaded. Adjust parameters and click update to retrieve some.</p>

        if (sessionDetailsData != null){
            mainContent = <>
                <h3 style={{marginTop: 15, marginBottom : 5}}>Session Details:</h3>
                <SessionDetailsTable/>
                <ClipboardElement labelStr = "Raw data" dataStr = {JSON.stringify(sessionDetailsData)} />
            </>
        }

        return (
            <>
                {optBar}
                <main>{mainContent}</main>
            </>
        );
    }


export default SessionDetailsContent;