import React from 'react';
import Ajax from './Ajax';
import { history } from './index'

export const AppContext = React.createContext();

const initialState = {
    appLoaded: false,
    username: null,
    token: null,
    monitoringData: null,
    outcomeData: null,
    outcomeLabels: null,
    timingData: null,
    customers: null,
    usernames: null,
    services: null,
    errors: []
}

export default class AppProvider extends React.Component {

    state = {...initialState}

    render() {
        return(
            <AppContext.Provider value={{
                state: this.state,
                init: this.init,
                reset: this.reset,
                loginOAuth: this.loginOAuth,
                currentUserIsAdmin: this.currentUserIsAdmin,
                setUser: this.setUser,
                setOutcomeData: this.setOutcomeData,
                setOutcomeLabels : this.setOutcomeLabels,
                setTimingData: this.setTimingData,
                setEnquiryDetailsData: this.setEnquiryDetailsData,
                setSessionDetailsData: this.setSessionDetailsData,
                setMonitoringData: this.setMonitoringData,
                setCustomers: this.setCustomers,
                setUsernames: this.setUsernames,
                getURLPeram: this.getURLPeram,
                getCookie: this.getCookie,
                closeError: this.closeError
            }}>
                {this.props.children}
            </AppContext.Provider>)
    }

    reset = () => {
        window.localStorage.removeItem("aora_dashboard_jwt")
        this.setState({...initialState, appLoaded: true})
        history.push("/")
    }

    init = async () => {
        await Ajax.Session.Get().then((user) => {
            this.setState({username: user.email})
        }).catch(() => 
            this.reset()
        ).finally(() => 
            this.setState({appLoaded: true})
        )
    }

    loginOAuth = async (code) => {
        await Ajax.Session.LoginOAuth(code).then((user) => {

            window.localStorage.setItem("aora_dashboard_jwt", user.token)
            this.setState({username: user.email})

            history.push("/")

        }).catch(response => {
            console.log(response)
            this.displayError(response.detail)
        }).finally(() => 
            this.setState({appLoaded: true})
        )
    }
    getServices = async () => {
        var services = await Ajax.Service.Get()
        this.setState({services: services})
    }

    setUser = (user) => {
        this.setState({user : user});
    }

    setOutcomeData = (data) => {
        //console.log(`AppContext.setOutcomeData called with data = ${data}`);
        this.setState({outcomeData : data});
    }

    setOutcomeLabels = (labels) => {
        //console.log(`AppContext.setOutcomeLabels called with data = ${labels}`);
        this.setState({outcomeLabels : labels});
    }

    setTimingData = (data) => {
        //console.log(`AppContext.setTimingData called with data = ${data}`);
        this.setState({timingData : data});
    }

    setEnquiryDetailsData = (data) => {
        this.setState({enquiryDetailsData : data});
    }

    setSessionDetailsData = (data) => {
        this.setState({sessionDetailsData : data});
    }

    setCustomers = (data) => {
        //console.log(`AppContext.setCustomers called with data = ${data}`);
        this.setState({customers : data});
    }

    setUsernames = (data) => {
        this.setState({usernames : data});
    }

    setMonitoringData = (data) => {
        //console.log(`AppContext.setMonitoringData called with data = ${data}`);
        this.setState({monitoringData : data});
    }

    currentUserIsAdmin = () => {
        return this.state.user != null && this.state.user.includes('@aoralaw.com');
    }

    closeError = (index) => {
        var errors = [...this.state.errors]
        errors.splice(index, 1)
        this.setState({errors: errors})
    }

    displayError = (message) => {
        var message = message ? message : "Something went wrong"
        var errors = [...this.state.errors, message]
        this.setState({errors: errors})
    }

    getURLPeram = (url, name) => {

        if (url && url.split("?").length > 1) {
            var query_string = url.split("?")[1]
            var perams = query_string.split("&")

            for (let i = 0; i < perams.length; i++) {
                if (perams[i].split("=")[0] === name && perams[i].split("=").length > 1) {
                    return perams[i].split("=")[1]
                }
            }
        }
        return null
    }

    getCookie = (cname) => {

        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}