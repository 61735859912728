const host = process.env.NODE_ENV === 'development' 
    ? "http://localhost:9191"
    : "https://dashboard.aoralaw.com"

const generateHeaders = () => {
    const token = window.localStorage.getItem("aora_dashboard_jwt")
    
    var headers = {
        Authorization: `Bearer ${token}`
    }

    return headers
}

const requests = {
    get: async (uri) => {
        var response = await fetch(`${host}${uri}`, {headers: generateHeaders(), credentials: "include", method: "GET"})

        if (response.status === 401) {
            window.location.reload()
        }

        if (!response.ok) throw await response.json()

        return response.json()
    },
    post: async (uri, data) => {
        var response = await fetch(`${host}${uri}`, {headers: generateHeaders(), credentials: "include", method: "POST", body: data})
        
        if (response.status === 401) {
            window.location.reload()
        }

        if (!response.ok) throw await response.json()
        
        return await response.json()
    }
}

const Ajax = {
    Session: {
        Get: async () => await requests.get("/api/session"),
        LoginOAuth: async (code) => await requests.get(`/api/session/login-oauth/${code}`)
    },
    Service: {
        Get: async () => await requests.get(`/api/service/ok`)
    },
    Timing : {
        GetTimingInfo: async (fromDt, toDt, status, env, customerId) => await requests.get(`/api/timing/question_stats/${fromDt}/${toDt}/${env}/${customerId}`),
        GetEnquiryTimes: async (fromDt, toDt, env) => await requests.get(`/api/timing/enquiry_times/${fromDt}/${toDt}/${env}`),
        GetSessionDetails: async (fromDt, toDt, env) => await requests.get(`/api/timing/session_details/${fromDt}/${toDt}/${env}`)
    },
    Usage: {
        GetOutcomes: async (period, numPeriods, status, env, customerId) => await requests.get(`/api/usage/${period}/${status}/${numPeriods}/${env}/${customerId}`),
        GetCustomers: async (env) => await requests.get(`/api/usage/parent_accounts/${env}`),
    }
}

export default Ajax