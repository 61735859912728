import React, {useContext, useState} from 'react';
import {AutoSizer, Column, Table} from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import {AppContext} from '../AppContext';


export default function EnquiryDetailsTable(){

    const context = React.useContext(AppContext);
    const data = context.state.enquiryDetailsData;

    const [sortKey, setSortKey] = useState("")
    function updateSortKey(key){

        var sortIndex = 0;

        if (key === "enquiry_id") sortIndex = 0;
        if (key === "customer_name") sortIndex = 1;
        if (key === "user_id") sortIndex = 2;
        if (key === "username") sortIndex = 3;
        if (key === "category") sortIndex = 4;
        if (key === "statuses") sortIndex = 5;
        if (key === "created") sortIndex = 7;
        if (key === "completed") sortIndex = 8;
        if (key === "sum_of_question_times") sortIndex = 9;
        if (key === "comment") sortIndex = 10;

        data.sort(function(row1, row2){return row2[sortIndex] - row1[sortIndex]});
        setSortKey(key);
    }


    function ColHeader ({colkey}){

        var disp_text = colkey;
        var hover_text = "";
        if (colkey === "enquiry_id"){
            disp_text = "Enquiry";
            hover_text = "Identifier for the enquiry";
        }
        if (colkey === "customer_name"){
            disp_text = "Customer Name";
            hover_text = "";
        }
        if (colkey === "user_id"){
            disp_text = "User ID";
            hover_text = "";
        }
        if (colkey === "username"){
            disp_text = "Login";
            hover_text = "";
        }
        if (colkey === "category"){
            disp_text = "Category";
            hover_text = "";
        }
        if (colkey === "statuses"){
            disp_text = "Statuses";
            hover_text = "The statuses that have reached definitive outcomes";
        }
        if (colkey === "comment"){
            disp_text = "Comment";
            hover_text = "";
        }
        if (colkey === "created"){
            disp_text = "Created";
            hover_text = "When the enquiry was created";
        }
        if (colkey === "completed"){
            disp_text = "Completed";
            hover_text = "Enquiry completion time";
        }
        if (colkey === "sum_of_question_times"){
            disp_text = "Q&A Time(s)";
            hover_text = "Enquiry time calculated by summing the time taken for each question in the enquiry";
        }

        const sort_icon = (sortKey === colkey) ? "▼" : "";
        const colText = <span>{disp_text}{sort_icon}</span>;
        return(
            <button title={hover_text} className="button-tableheader" onClick={() => { updateSortKey(colkey);}}>
                <p>{colText}</p>
            </button>
        );
    };

    const createData = (enquiry_id, customer_name, user_id, username, category, statuses, created, completed, sum_of_question_times, comment) => {
        return {
            enquiry_id: enquiry_id,
            user_id: user_id,
            customer_name: customer_name,
            username: username,
            category : category,
            statuses: statuses,
            created: created,
            completed: completed,
            sum_of_question_times: sum_of_question_times,
            comment, // TODO: display this when hovering over the category cell somehow?
        };
    };

    const list = data.map((row, index) => {
        return createData(row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8], row[9], row[10]);
    })

    // TODO: get autosizer working and make width + height dynamic
    const w = 1500;
    const h = 500;
    return (
        <Table
            className='main-content'
            headerClassName='table-header'
            headerStyle={{margin: 0, padding: 5}}
            width={w}
            height={h}
            headerHeight={36}
            rowHeight={24}
            rowCount={list.length}
            rowGetter={({index}) => list[index]}>
            <Column dataKey="enquiry_id" width={w*0.04} headerRenderer={() => <ColHeader colkey="enquiry_id"/>}/>
            <Column dataKey="customer_name" width={w*0.15} headerRenderer={() => <ColHeader colkey="customer_name"/>}/>
            <Column dataKey="user_id" width={w*0.05} headerRenderer={() => <ColHeader colkey="user_id"/>}/>
            <Column dataKey="username" width={w*0.15} headerRenderer={() => <ColHeader colkey="username"/>}/>
            <Column dataKey="category" width={w*0.09} headerRenderer={() => <ColHeader colkey="category"/>}/>
            <Column dataKey="statuses" width={w*0.2} headerRenderer={() => <ColHeader colkey="statuses"/>}/>
            <Column dataKey="created" width={w*0.125} headerRenderer={() => <ColHeader colkey="created"/>}/>
            <Column dataKey="completed" width={w*0.125} headerRenderer={() => <ColHeader colkey="completed"/>}/>
            <Column dataKey="sum_of_question_times" width={w*0.07} headerRenderer={() => <ColHeader colkey="sum_of_question_times"/>}/>
        </Table>

    );
}
