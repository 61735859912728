import React from 'react';
import {AppContext} from '../AppContext';


export default function HomeContent () {
    
    const context = React.useContext(AppContext);

    return (
        <>
            <h2>Welcome!</h2>
            <p>Welcome to the AORA Dashboard {context.state.username}. This site is split into several sections:</p>
            <ul>
                <li><b>Outcomes:</b> displays the number of enquiries that have been completed, and what proportion reached positive/negative outcomes.</li>
                <li><b>Enquiry Details:</b> detailed information about enquiries including statuses investigated and duration.</li>
                <li><b>Question Times:</b> in-depth timing statistics for questions.</li>
                <li><b>Session Details:</b> detailed information about sessions.</li>
                <li><b>Servers:</b> statistics relating to AORA's backend services and host machines.</li>
                <li><b>Known Issues:</b> can't query production servers for enquiry details or question times, since changes need to be deployed to support this.</li>
            </ul>
        </>
    )
}